import MarkerImage from '@/assets/images/marker.png';
import MarkerClusterImage from '@/assets/images/marker_cluster.png';
import { filterQueryParams } from '@/components/ReportTypesPanel/utils';
import { EListTypes, EReportName, EReportUnit } from '@/store/enums';
import {
  Cluster,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';

import { formatNumberWithCommas } from '../KeyReports/utils';

export interface MapData {
  latitude: number;
  longitude: number;
  agents: number;
  id: string;
  brandId: string | undefined;
  firmId: string | undefined;
  officeName: string;
  officeAddress: string | undefined;
  firmName: string | undefined;
  brandName: string | undefined;
  listingUnit: string | undefined;
  listingUnitPerAgent: string | undefined;
  listingUnitMarketShare: string | undefined;
  listingVolume: string | undefined;
  listingVolumePerAgent: string | undefined;
  listingVolumeMarketShare: string | undefined;
  sellingUnit: string | undefined;
  sellingUnitPerAgent: string | undefined;
  sellingUnitMarketShare: string | undefined;
  sellingVolume: string | undefined;
  sellingVolumePerAgent: string | undefined;
  sellingVolumeMarketShare: string | undefined;
  totalUnit: string | undefined;
  totalUnitPerAgent: string | undefined;
  totalUnitMarketShare: string | undefined;
  totalVolume: string | undefined;
  totalVolumePerAgent: string | undefined;
  totalVolumeMarketShare: string | undefined;
}

let currWindow: google.maps.InfoWindow | null = null;

export const createMarker = (
  map: google.maps.Map,
  {
    latitude,
    longitude,
    agents,
    id,
    brandId,
    firmId,
    officeName,
    officeAddress,
    listingUnit,
    listingUnitPerAgent,
    listingUnitMarketShare,
    listingVolume,
    listingVolumePerAgent,
    listingVolumeMarketShare,
    sellingUnit,
    sellingUnitPerAgent,
    sellingUnitMarketShare,
    sellingVolume,
    sellingVolumePerAgent,
    sellingVolumeMarketShare,
    totalUnit,
    totalUnitPerAgent,
    totalUnitMarketShare,
    totalVolume,
    totalVolumePerAgent,
    totalVolumeMarketShare,
    firmName,
    brandName,
  }: MapData,
  processedLongitudes: { [key: number]: boolean },
  reportType: string,
  reportName: string
): google.maps.Marker => {
  let title: string;

  if (
    [
      EReportName.AGENTS_REGISTERED,
      EReportName.OFFICES_REGISTERED,
      EReportName.OFFICE_REGISTERED_AGENT_COUNT,
      EReportName.FIRMS_REGISTERED,
      EReportName.FIRM_REGISTERED_AGENT_COUNT,
      EReportName.BRANDS_REGISTERED,
      EReportName.BRAND_REGISTERED_AGENT_COUNT,
    ].includes(reportName as EReportName)
  ) {
    title = `<strong>${agents}</strong> registered agent${
      agents !== 1 ? 's' : ''
    }`;
  } else if (reportName === EReportName.AGENTS_NEW_REGISTERED) {
    title = `<strong>${agents}</strong> new registered agent${
      agents !== 1 ? 's' : ''
    }`;
  } else {
    title = `<strong>${agents}</strong> active agent${agents !== 1 ? 's' : ''}`;
  }

  google.maps.event.addListener(map, 'click', function () {
    if (currWindow) {
      currWindow.close();
    }
  });

  const getHref = (params: Record<string, string>): string => {
    const urlParams = new URLSearchParams(
      filterQueryParams(window.location.search)
    );

    for (const [key, value] of Object.entries(params)) {
      if (value === '') {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value);
      }
    }

    const queryString = urlParams.toString();
    const url = `${window.location.origin}${window.location.pathname}`;

    return queryString ? `${url}?${queryString}` : url;
  };

  const brandUrl = () => {
    const params: Record<string, string> = {
      orderBy: '',
      brandIds: brandId?.toString() ?? '',
      officeIds: '',
      firmIds: '',
      reportName: EReportName.FIRMS,
      reportUnit: EReportUnit.FIRMS,
    };
    return `<a href="${getHref(params)}" target="_blank">${brandName}</a>`;
  };

  const firmUrl = () => {
    const params: Record<string, string> = {
      orderBy: '',
      firmIds: firmId?.toString() ?? '',
      brandIds: '',
      officeIds: '',
      reportName: EReportName.OFFICES,
      reportUnit: EReportUnit.OFFICES,
    };
    return `<a href="${getHref(params)}" target="_blank">${firmName}</a>`;
  };

  const officeUrl = () => {
    const params: Record<string, string> = {
      orderBy: '',
      officeIds: id?.toString() ?? '',
      brandIds: '',
      firmIds: '',
      reportName: EReportName.AGENTS,
      reportUnit: EReportUnit.AGENTS,
    };
    return `<a href="${getHref(params)}" target="_blank">${officeName}</a>`;
  };

  const infoWindowContent = () => {
    const keyPrefixMap = {
      [EListTypes.COMBINED]: 'total',
      [EListTypes.LISTING]: 'listing',
      [EListTypes.SELLING]: 'selling',
    };

    const keyPrefix = keyPrefixMap[reportType as EListTypes];

    const getUnitContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const unitValue = {
        total: totalUnit,
        listing: listingUnit,
        selling: sellingUnit,
      }[prefix];
      return unitValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Units:</strong> ${formatNumberWithCommas(Number(unitValue))}<br>`
        : '';
    };

    const getUnitPerAgentContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const unitPerAgentValue = {
        total: totalUnitPerAgent,
        listing: listingUnitPerAgent,
        selling: sellingUnitPerAgent,
      }[prefix];
      return unitPerAgentValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Units per Agent:</strong> ${formatNumberWithCommas(
            Number(unitPerAgentValue)
          )}<br>`
        : '';
    };

    const getMarketShareContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const marketShareValue = {
        total: totalUnitMarketShare,
        listing: listingUnitMarketShare,
        selling: sellingUnitMarketShare,
      }[prefix];
      return marketShareValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Market Share:</strong> ${formatNumberWithCommas(
            Number(marketShareValue)
          )}%<br>`
        : '';
    };

    const getVolumeContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const volumeValue = {
        total: totalVolume,
        listing: listingVolume,
        selling: sellingVolume,
      }[prefix];
      return volumeValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Volume:</strong> ${formatNumberWithCommas(Number(volumeValue))}<br>`
        : '';
    };

    const getVolumePerAgentContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const volumePerAgentValue = {
        total: totalVolumePerAgent,
        listing: listingVolumePerAgent,
        selling: sellingVolumePerAgent,
      }[prefix];
      return volumePerAgentValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Volume per Agent:</strong> ${formatNumberWithCommas(
            Number(volumePerAgentValue)
          )}<br>`
        : '';
    };

    const getVolumeMarketShareContent = (prefix: string) => {
      let prefixText = '';
      if (prefix === 'total') {
        prefixText = 'Total';
      } else if (prefix === 'selling') {
        prefixText = 'Sold';
      } else if (prefix === 'listing') {
        prefixText = 'List';
      }

      const volumeMarketShareValue = {
        total: totalVolumeMarketShare,
        listing: listingVolumeMarketShare,
        selling: sellingVolumeMarketShare,
      }[prefix];
      return volumeMarketShareValue != null
        ? `<strong>${
            prefixText.charAt(0).toUpperCase() + prefixText.slice(1)
          } Volume Market Share:</strong> ${formatNumberWithCommas(
            Number(volumeMarketShareValue)
          )}%<br>`
        : '';
    };

    return `
    <div style="font-size: 14px">
      ${brandName ? `<strong>Brand:</strong> ${brandUrl()}<br>` : ''}
      ${firmName ? `<strong>Firm:</strong> ${firmUrl()}<br>` : ''}
      ${officeName ? `<strong>Office:</strong> ${officeUrl()}<br>` : ''}
      ${officeAddress ? `<strong>Address:</strong> ${officeAddress}<br>` : ''}
      ${keyPrefix ? getUnitContent(keyPrefix) : ''}
      ${keyPrefix ? getUnitPerAgentContent(keyPrefix) : ''}
      ${keyPrefix ? getMarketShareContent(keyPrefix) : ''}
      ${keyPrefix ? getVolumeContent(keyPrefix) : ''}
      ${keyPrefix ? getVolumePerAgentContent(keyPrefix) : ''}
      ${keyPrefix ? getVolumeMarketShareContent(keyPrefix) : ''}
      ${title}
    </div>
    `;
  };

  const infoWindow = new google.maps.InfoWindow({
    content: infoWindowContent(),
    ariaLabel: title,
    disableAutoPan: true,
  });

  if (processedLongitudes[longitude]) {
    longitude += 0.00001;
  } else {
    processedLongitudes[longitude] = true;
  }

  const marker = new google.maps.Marker({
    position: { lat: longitude, lng: latitude },
    map,
    icon: MarkerImage,
    title: `${officeName}\n${officeAddress}`,
  });

  marker.addListener('click', () => {
    if (currWindow) {
      currWindow.close();
    }
    infoWindow.open({ anchor: marker, map });
    currWindow = infoWindow;
  });

  return marker;
};

export const createClusterMarker = (cluster: Cluster): google.maps.Marker => {
  //@ts-ignore
  const { count, position } = cluster;

  return new google.maps.Marker({
    position,
    icon: {
      url: MarkerClusterImage,
      scaledSize: new google.maps.Size(70, 70),
      labelOrigin: new google.maps.Point(35, 25),
    },
    label: {
      text: String(count),
      color: 'rgba(0,0,0,0.9)',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    title: `Cluster of ${count} offices`,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  });
};

export const createMarkerClusterer = (
  markers: google.maps.Marker[],
  map: google.maps.Map
): void => {
  new MarkerClusterer({
    //@ts-ignore
    markers,
    map,
    algorithm: new SuperClusterAlgorithm({ radius: 200 }),
    renderer: {
      render: createClusterMarker,
    },
  });
};
