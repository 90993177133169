import React from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import { objToQueryString } from '@/router/router';
import { EDistributionData, EReportName } from '@/store/enums';
import { AppDispatch, store } from '@/store/index';
import { getSavedSearchList, setKeySettings } from '@/store/keySettingsSlice';
import { initConfig } from '@/store/reportFieldsSlice';
import {
  IAvailableReports,
  IReportData,
  TReportItem,
  TReportMembers,
  TReportTotalItems,
  TShortInfo,
  TTotals,
} from '@/store/types';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  report: {
    loading: boolean;
    mapLoading: boolean;
    displayMap: boolean;
    reset: boolean;
    items: Array<TReportItem>;
    totals: TTotals;
    secondTotals: TTotals;
    shortInfo: TShortInfo;
    mapItems: Array<any>;
    totalItems: TReportTotalItems | number;
    secondTotalItems: TReportTotalItems | number;
    members: Array<TReportMembers>;
    data: string;
    availableReports: IAvailableReports;
    userAvailableAppliedSettings: any;
  };
}

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    loading: false,
    mapLoading: false,
    displayMap: false,
    reset: false,
    items: [],
    totals: {},
    secondTotals: {},
    shortInfo: {},
    mapItems: [],
    totalItems: 0,
    secondTotalItems: 0,
    members: [],
    data: '',
    availableReports: {},
    userAvailableAppliedSettings: {},
  },
  reducers: {
    setReport: (
      state,
      action: {
        payload: {
          value: Omit<IReportData, 'totals'>;
        };
        type: string;
      }
    ) =>
      Object.assign(state || {}, {
        loading: false,
        reset: false,
        items: action.payload.value.items,
        graphType: action.payload.value.graphType,
        orderBy: action.payload.value.orderBy,
        data: action.payload.value.data,
        chartType: action.payload.value.chartType,
        timePeriod: action.payload.value.timePeriod,
        orderDirection: action.payload.value.orderDirection,
        reportType: action.payload.value.reportType,
        members: action.payload.value.members,
      }),
    setTotals: (
      state,
      action: {
        payload: {
          totals: TTotals;
        };
      }
    ) =>
      Object.assign(state || {}, {
        totals: action.payload.totals,
      }),
    setSecondTotals: (
      state,
      action: {
        payload: {
          secondTotals: TTotals;
        };
      }
    ) =>
      Object.assign(state || {}, {
        secondTotals: action.payload.secondTotals,
      }),
    setShortInfo: (state, action) =>
      Object.assign(state || {}, {
        shortInfo: action.payload,
      }),
    setUserAvailableAppliedSettings: (state, action) =>
      Object.assign(state || {}, {
        userAvailableAppliedSettings: action.payload,
      }),
    setTotalItems: (state, action) =>
      Object.assign(state || {}, {
        totalItems: action.payload,
      }),
    setSecondTotalItems: (state, action) =>
      Object.assign(state || {}, {
        secondTotalItems: action.payload,
      }),
    setAvailableReports: (state, action) =>
      Object.assign(state || {}, {
        availableReports: action.payload,
      }),
    setMapItems: (state, action) =>
      Object.assign(state || {}, {
        mapItems: action.payload,
        mapLoading: false,
      }),
    setLoading: (state) => Object.assign(state, { loading: true }),
    setMapLoading: (state) => Object.assign(state, { mapLoading: true }),
    setDisplayMap: (state, action) =>
      Object.assign(state || {}, {
        displayMap: action.payload,
      }),
    resetReport: (state, action) =>
      Object.assign(state, {
        reset: action.payload.reset,
        items: [],
        totals: {},
        totalItems: 0,
      }),
  },
});

export const {
  setReport,
  setShortInfo,
  setTotals,
  setSecondTotals,
  setMapItems,
  setTotalItems,
  setSecondTotalItems,
  resetReport,
  setLoading,
  setMapLoading,
  setDisplayMap,
  setAvailableReports,
  setUserAvailableAppliedSettings,
} = reportSlice.actions;

export const getReport = (state: InitialState) => state.report;

export default reportSlice.reducer;

export const initReport =
  (data: Omit<IReportData, 'totals'>) => async (dispatch: AppDispatch) => {
    dispatch(setReport({ value: data }));
  };

let loadUserAvailableAppliedSettingsController: AbortController | undefined;

const loadUserAvailableAppliedSettings =
  (mls: number) => (dispatch: AppDispatch) => {
    if (loadUserAvailableAppliedSettingsController) {
      loadUserAvailableAppliedSettingsController.abort();
    }

    loadUserAvailableAppliedSettingsController = new AbortController();
    const signal = loadUserAvailableAppliedSettingsController.signal;

    return apiClient
      .getUserAvailableAppliedSettings(mls, signal)
      .then((data) => {
        dispatch(setUserAvailableAppliedSettings(data));
      })
      .catch((error) => {
        dispatch(setUserAvailableAppliedSettings({}));
        return Promise.reject(error);
      });
  };

let loadMainEntitiesShortInfoController: AbortController | undefined;

const loadMainEntitiesShortInfo =
  (mls: number, query: string) => (dispatch: AppDispatch) => {
    if (loadMainEntitiesShortInfoController) {
      loadMainEntitiesShortInfoController.abort();
    }

    loadMainEntitiesShortInfoController = new AbortController();
    const signal = loadMainEntitiesShortInfoController.signal;

    return apiClient
      .getMainEntitiesShortInfo(mls, query, signal)
      .then((data) => {
        dispatch(setShortInfo(data));
      })
      .catch((error) => {
        dispatch(resetReport({ reset: false }));
        return Promise.reject(error);
      });
  };

let loadTotalsController: AbortController | undefined;

const loadTotals =
  (mls: number, reportName: string, query: string) =>
  (dispatch: AppDispatch) => {
    if (loadTotalsController) {
      loadTotalsController.abort();
    }

    loadTotalsController = new AbortController();
    const signal = loadTotalsController.signal;

    return apiClient
      .getReportTotals(mls, reportName, query, signal)
      .then((data) => {
        dispatch(setTotals({ totals: data }));
      })
      .catch((error) => {
        dispatch(resetReport({ reset: false }));
        return Promise.reject(error);
      });
  };

const loadSecondTotals =
  (mls: number, reportName: string, query: string) =>
  (dispatch: AppDispatch) => {
    return apiClient
      .getReportSecondTotals(mls, reportName, query)
      .then((data) => {
        dispatch(setSecondTotals({ secondTotals: data }));
      })
      .catch((error) => {
        dispatch(resetReport({ reset: false }));
        return Promise.reject(error);
      });
  };

const loadGraphsTotals =
  (mls: number, reportName: string, query: string) =>
  (dispatch: AppDispatch) => {
    return apiClient
      .getReportGraphsTotals(mls, reportName, query)
      .then((data) => {
        dispatch(setTotals({ totals: data }));
      })
      .catch((error) => {
        dispatch(resetReport({ reset: false }));
        return Promise.reject(error);
      });
  };

let loadTotalItemsController: AbortController | undefined;

const loadTotalItems =
  (mls: number, reportName: string, query: string) =>
  (dispatch: AppDispatch) => {
    if (loadTotalItemsController) {
      loadTotalItemsController.abort();
    }

    loadTotalItemsController = new AbortController();
    const signal = loadTotalItemsController.signal;

    return apiClient
      .getReportTotalItems(mls, reportName, query, signal)
      .then((data) => {
        dispatch(setTotalItems(data));
      })
      .catch((error) => {
        dispatch(resetReport({ reset: false }));
        return Promise.reject(error);
      });
  };

const loadMapItems =
  (mls: number, reportName: string, query: string) =>
  (dispatch: AppDispatch) => {
    dispatch(setMapLoading());
    return apiClient
      .searchOfficeMap(mls, reportName, query)
      .then((data) => {
        dispatch(setMapItems(data));
      })
      .catch((error) => {
        dispatch(setMapItems(null));
        return Promise.reject(error);
      });
  };

export const loadReportMapItems = () => (dispatch: AppDispatch) => {
  const { mls, reportName, orderBy, ...fields } = store.getState().keySettings;

  const selectedOrderBy = orderBy ? orderBy : orderByMapping[reportName];

  const query = `?${objToQueryString({
    ...fields,
    orderBy: selectedOrderBy,
  })}`;

  dispatch(setMapLoading());
  return apiClient
    .searchOfficeMap(mls || 1, reportName, query)
    .then((data) => {
      dispatch(setMapItems(data));
    })
    .catch((error) => {
      dispatch(setMapItems(null));
      return Promise.reject(error);
    });
};

export const loadMapTransactionItems =
  (boundsArray: number[]) => (dispatch: AppDispatch) => {
    // eslint-disable-next-line
    const {
      mls,
      reportName,
      savedSearch,
      savedSearchList,
      orderBy,
      ...fields
    } = store.getState().keySettings;

    const selectedOrderBy = orderBy ? orderBy : orderByMapping[reportName];

    if (!mls) return;

    const query = `?${objToQueryString({
      ...fields,
      orderBy: selectedOrderBy,
      bounds: boundsArray,
    })}`;

    return apiClient
      .searchTransactionsMap(mls, reportName, query)
      .then((data) => {
        dispatch(setMapItems(data));
      })
      .catch((error) => {
        dispatch(setMapItems(null));
        return Promise.reject(error);
      });
  };

export const loadMapCountTransactionItems =
  (boundsArray: number[]) => (dispatch: AppDispatch) => {
    // eslint-disable-next-line
    const {
      mls,
      reportName,
      savedSearch,
      savedSearchList,
      orderBy,
      ...fields
    } = store.getState().keySettings;

    const selectedOrderBy = orderBy ? orderBy : orderByMapping[reportName];

    if (!mls) return;

    const query = `?${objToQueryString({
      ...fields,
      orderBy: selectedOrderBy,
      bounds: boundsArray,
    })}`;

    return apiClient
      .searchTransactionsCountMap(mls, reportName, query)
      .catch((error) => {
        return Promise.reject(error);
      });
  };

export const loadMapClusterTransactionItems =
  (boundsArray: number[]) => (dispatch: AppDispatch) => {
    // eslint-disable-next-line
    const {
      mls,
      reportName,
      savedSearch,
      savedSearchList,
      orderBy,
      ...fields
    } = store.getState().keySettings;

    const selectedOrderBy = orderBy ? orderBy : orderByMapping[reportName];

    if (!mls) return;

    const query = `?${objToQueryString({
      ...fields,
      orderBy: selectedOrderBy,
      bounds: boundsArray,
    })}`;

    return apiClient
      .searchTransactionsMapCluster(mls, reportName, query)
      .then((data) => {
        dispatch(setMapItems(data));
      })
      .catch((error) => {
        dispatch(setMapItems(null));
        return Promise.reject(error);
      });
  };

const orderByMapping: Record<EReportName, string | null> = {
  [EReportName.AGENTS]: null,
  [EReportName.AGENTS_YEARBOOK]: null,
  [EReportName.AGENTS_STATUS]: null,
  [EReportName.AGENTS_DISTRIBUTION]: null,
  [EReportName.AGENTS_REGISTERED]: null,
  [EReportName.AGENTS_COMPARATIVE]: null,
  [EReportName.AGENTS_NEW_ACTIVE]: null,
  [EReportName.AGENT_ROSTER_MIGRATION]: null,
  [EReportName.AGENT_HISTORY]: null,
  [EReportName.AGENT_PROGRESS]: null,
  [EReportName.AGENT_DETAILED_SUMMARY]: null,
  [EReportName.AGENT_DISTRIBUTION]: null,
  [EReportName.AGENT_MIGRATION_SUMMARY]: null,
  [EReportName.AGENT_MIGRATION_DETAILS]: null,
  [EReportName.AGENT_MIGRATION_POTENTIAL]: null,
  [EReportName.OFFICES]: null,
  [EReportName.OFFICES_REGISTERED]: null,
  [EReportName.OFFICE_REGISTERED_AGENT_COUNT]: null,
  [EReportName.OFFICES_COMPARATIVE_PERFORMANCE]: null,
  [EReportName.OFFICES_DISTRIBUTION]: null,
  [EReportName.OFFICE_DISTRIBUTION]: null,
  [EReportName.OFFICE_MIGRATION]: null,
  [EReportName.FIRMS_COMPARATIVE_PERFORMANCE]: null,
  [EReportName.BRANDS_COMPARATIVE_PERFORMANCE]: null,
  [EReportName.OFFICES_TOP_TEN]: null,
  [EReportName.FIRMS_TOP_TEN]: null,
  [EReportName.BRANDS_TOP_TEN]: null,
  [EReportName.FIRMS]: null,
  [EReportName.FIRMS_DISTRIBUTION]: null,
  [EReportName.FIRM_REGISTERED_AGENT_COUNT]: null,
  [EReportName.FIRM_DISTRIBUTION]: null,
  [EReportName.BRANDS]: null,
  [EReportName.PERFORMANCE]: null,
  [EReportName.MARKET]: null,
  [EReportName.MARKET_COMPARATIVE]: null,
  [EReportName.MARKET_PERFORMANCE_DISTRIBUTION]: null,
  [EReportName.MARKET_COMPARATIVE_DISTRIBUTION]: null,
  [EReportName.INVENTORY]: null,
  [EReportName.MONTHLY_HOUSING_STATISTICS]: null,
  [EReportName.MARKET_OVERVIEW_PRICE_RANGE]: null,
  [EReportName.AGENTS_NEW_REGISTERED]: null,
  [EReportName.BRANDS_REGISTERED]: null,
  [EReportName.BRANDS_DISTRIBUTION]: null,
  [EReportName.BRAND_REGISTERED_AGENT_COUNT]: null,
  [EReportName.BRAND_DISTRIBUTION]: null,
  [EReportName.FIRMS_REGISTERED]: null,
  [EReportName.AGENT_TRANSACTIONS]: null,
  [EReportName.OFFICE_TRANSACTIONS]: null,
  [EReportName.FIRM_TRANSACTIONS]: null,
  [EReportName.BRAND_TRANSACTIONS]: null,
};

let loadReportController: AbortController | undefined;

export const loadReport =
  (showMessage = false, loadInitConfig = true) =>
  (dispatch: AppDispatch) => {
    dispatch(resetReport({ reset: false }));
    dispatch(setLoading());
    loadInitConfig && dispatch(initConfig());

    if (loadReportController) {
      loadReportController.abort();
    }

    loadReportController = new AbortController();
    const signal = loadReportController.signal;

    // eslint-disable-next-line
    const {
      mls,
      reportName,
      savedSearch,
      savedSearchList,
      orderBy,
      data,
      ...fields
    } = store.getState().keySettings;

    const selectedOrderBy = orderBy ? orderBy : orderByMapping[reportName];

    if (!mls) return;

    const query = `?${objToQueryString({
      ...fields,
      data,
      orderBy: selectedOrderBy,
    })}`;

    dispatch(loadUserAvailableAppliedSettings(mls));

    if (reportName === EReportName.PERFORMANCE) {
      dispatch(loadMainEntitiesShortInfo(mls, query));

      return apiClient
        .getGraphsReport(mls, reportName, query)
        .then((data) => {
          dispatch(initReport(data));

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    } else if (reportName === EReportName.MARKET) {
      dispatch(loadMainEntitiesShortInfo(mls, query));

      return apiClient
        .getGraphsReport(mls, reportName, query)
        .then((data) => {
          dispatch(initReport(data));

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    } else if (reportName === EReportName.MARKET_COMPARATIVE) {
      dispatch(loadMainEntitiesShortInfo(mls, query));

      return apiClient
        .getGraphsReport(mls, reportName, query)
        .then((data) => {
          dispatch(initReport(data));

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    } else if (
      reportName === EReportName.OFFICES_TOP_TEN ||
      reportName === EReportName.FIRMS_TOP_TEN ||
      reportName === EReportName.BRANDS_TOP_TEN
    ) {
      return apiClient
        .getReport(mls, reportName, query, signal)
        .then((data) => {
          dispatch(initReport(data));

          if (data.orderBy) {
            dispatch(
              setKeySettings({
                key: 'orderBy',
                value: data.orderBy,
              })
            );
          }

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    } else if (
      [
        EReportName.AGENTS_DISTRIBUTION,
        EReportName.OFFICES_DISTRIBUTION,
        EReportName.FIRMS_DISTRIBUTION,
        EReportName.BRANDS_DISTRIBUTION,
      ].includes(reportName as EReportName)
    ) {
      if (
        data !== EDistributionData.PRICE_10000 ||
        data !== EDistributionData.PRICE_25000 ||
        data !== EDistributionData.PRICE_50000 ||
        data !== EDistributionData.PRICE_100000 ||
        data !== EDistributionData.PRICE_500000
      ) {
        dispatch(loadSecondTotals(mls, reportName, query));
      }
      dispatch(loadMainEntitiesShortInfo(mls, query));
      dispatch(loadTotals(mls, reportName, query));
      dispatch(loadTotalItems(mls, reportName, query));

      return apiClient
        .getReport(mls, reportName, query, signal)
        .then((data) => {
          dispatch(initReport(data));

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    } else {
      dispatch(loadMainEntitiesShortInfo(mls, query));
      dispatch(loadTotals(mls, reportName, query));
      dispatch(loadTotalItems(mls, reportName, query));

      return apiClient
        .getReport(mls, reportName, query, signal)
        .then((data) => {
          dispatch(initReport(data));

          if (showMessage) {
            data.items.length
              ? toast.success(<Trans i18nKey="messages.report_success" />)
              : toast.info(<Trans i18nKey="messages.no_results" />);
          }

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch(resetReport({ reset: false }));
          if (error.name !== 'CanceledError') {
            toast.error(<Trans i18nKey="messages.empty_report" />);
          }
          return Promise.reject(error);
        });
    }
  };
