import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import { ReportsMenu } from '@/components/ReportsMenu';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledSelect from '@/mui-styled-components/select';
import { objToQueryString } from '@/router/router';
import { AppDispatch, store } from '@/store';
import { EFileTypes, EReportName, EReportTypes } from '@/store/enums';
import {
  getKeySettings,
  getSavedSearchList,
  resetFilters,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getReport, loadReport, setDisplayMap } from '@/store/reportSlice';
import { HeadCell } from '@/store/types';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  CircularProgress,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import ReportTypeSettings from './ReportTypeSettings';

import './ReportTypesPanel.scss';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 320,
  bgcolor: '#fff',
  boxShadow: 24,
  p: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

interface IProps {
  headCells: Array<HeadCell>;
  toggleColumnVisibility: (id: string, boolean: boolean) => void;
}

const ReportTypesPanel = (props: IProps) => {
  const { headCells, toggleColumnVisibility } = props;
  const { mls, reportType, reportUnit, reportName, data } =
    useSelector(getKeySettings);
  const { items, totalItems, mapLoading, loading, displayMap } =
    useSelector(getReport);
  const [disabledExport, setDisabledExport] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleChangeReportType = (event: SelectChangeEvent) => {
    dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(
      setKeySettings({
        key: 'reportType',
        value: event.target.value,
      })
    );
    dispatch(
      setKeySettings({
        key: 'orderBy',
        value: null,
      })
    );

    if (
      ![
        EReportName.AGENTS_DISTRIBUTION,
        EReportName.OFFICES_DISTRIBUTION,
        EReportName.FIRMS_DISTRIBUTION,
        EReportName.BRANDS_DISTRIBUTION,
        EReportName.MARKET,
        EReportName.MARKET_COMPARATIVE,
      ].includes(reportName as EReportName) &&
      reportName === EReportName.PERFORMANCE &&
      !['totalUnit', 'totalVolume', 'avgPrice', 'medianPrice'].includes(
        data as string
      )
    ) {
      dispatch(
        setKeySettings({
          key: 'data',
          value: null,
        })
      );
    }
    dispatch(resetFilters(reportName));

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const { t } = useTranslation();

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => setOpen(false);

  const exportReportPdf = () => {
    setDisabledExport(true);
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const {
      mls,
      timeFrame,
      reportName,
      savedSearch,
      savedSearchList,
      ...fields
    } = store.getState().keySettings;

    if (!mls) return;

    const query = objToQueryString(fields);
    const type = EFileTypes.TYPE_PDF;

    return apiClient
      .exportReport(mls, reportName, query, type)
      .then((response) => {
        setDisabledExport(!items.length);
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        handleOpenModal();
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportReportExcel = () => {
    setDisabledExport(true);
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const {
      mls,
      timeFrame,
      reportName,
      savedSearch,
      savedSearchList,
      ...fields
    } = store.getState().keySettings;

    if (!mls) return;

    const query = objToQueryString(fields);
    const type =
      typeof totalItems === 'number' && totalItems > 30000
        ? EFileTypes.TYPE_CSV
        : EFileTypes.TYPE_XLSX;

    return apiClient
      .exportReport(mls, reportName, query, type)
      .then((response) => {
        setDisabledExport(!items.length);
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        handleOpenModal();
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    setDisabledExport(!items.length);
  }, [items]);

  useEffect(() => {
    dispatch(setDisplayMap(false));
  }, [reportUnit, mls]);

  const itemStyle = {
    cursor: 'pointer',
    marginLeft: '10px',
  };

  return (
    <div className="report-types-panel">
      <ReportsMenu />
      <div className="ml-auto">
        {![
          EReportName.AGENT_DISTRIBUTION,
          EReportName.OFFICE_DISTRIBUTION,
          EReportName.FIRM_DISTRIBUTION,
          EReportName.BRAND_DISTRIBUTION,
          EReportName.MARKET,
          EReportName.MARKET_COMPARATIVE,
          EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
          EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
          EReportName.AGENT_ROSTER_MIGRATION,
          EReportName.OFFICE_REGISTERED_AGENT_COUNT,
          EReportName.FIRM_REGISTERED_AGENT_COUNT,
          EReportName.BRAND_REGISTERED_AGENT_COUNT,
          EReportName.OFFICE_MIGRATION,
        ].includes(reportName as EReportName) && (
          <div
            title={
              !displayMap
                ? t('map_search.switch_to_map_view')
                : t('map_search.switch_to_table_view')
            }
            onClick={() =>
              !mapLoading ? dispatch(setDisplayMap(!displayMap)) : null
            }
            onTouchStart={() =>
              !mapLoading ? dispatch(setDisplayMap(!displayMap)) : null
            }
            style={itemStyle}
          >
            {mapLoading ? (
              <CircularProgress sx={{ color: 'var(--blue-color)' }} size={20} />
            ) : !displayMap ? (
              <SpriteIcon
                style={{ width: '24px', height: '24px' }}
                icon="room_icon"
              />
            ) : (
              <SpriteIcon
                style={{ width: '24px', height: '24px' }}
                icon="table_view"
              />
            )}
          </div>
        )}
      </div>
      <StyledSelect
        value={reportType}
        displayEmpty
        disabled={[
          EReportName.AGENTS_STATUS,
          EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
          EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
          EReportName.OFFICE_REGISTERED_AGENT_COUNT,
          EReportName.FIRM_REGISTERED_AGENT_COUNT,
          EReportName.BRAND_REGISTERED_AGENT_COUNT,
          EReportName.OFFICE_MIGRATION,
          EReportName.MARKET,
          EReportName.MARKET_COMPARATIVE,
        ].includes(reportName as EReportName)}
        //@ts-ignore
        onChange={handleChangeReportType}
        inputProps={{ 'aria-label': 'Without label' }}
        className="report-type-select"
        IconComponent={() => <SpriteIcon icon="arrow_down" />}
      >
        {Object.values(EReportTypes).map((item, index) => (
          <MenuItem key={index} value={item}>
            {t(`reports.${item}`)}
          </MenuItem>
        ))}
      </StyledSelect>
      <ReportTypeSettings
        headCells={headCells}
        toggleColumnVisibility={toggleColumnVisibility}
      />
      <LoadingButton
        variant="outlined"
        className="export-btn outlined-btn"
        disabled={disabledExport}
        loading={loading}
        onClick={exportReportPdf}
        onTouchStart={exportReportPdf}
      >
        {t('buttons.export_pdf')}
      </LoadingButton>
      <LoadingButton
        variant="outlined"
        className="export-btn outlined-btn"
        disabled={disabledExport}
        loading={loading}
        onClick={exportReportExcel}
        onTouchStart={exportReportExcel}
      >
        {t('buttons.export_excel')}
      </LoadingButton>
      <Modal
        keepMounted
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        classes={{ root: 'coc-modal saved-search-modal' }}
      >
        <Box sx={style}>
          <h3 style={{ marginTop: 0, textAlign: 'center' }}>
            {t('messages.export_success')}
          </h3>
          <h3
            style={{ marginTop: 0, marginBottom: '25px', textAlign: 'center' }}
          >
            {t('messages.check_downloads_folder')}
          </h3>
          <Button
            style={{ fontWeight: 'bold' }}
            className="filled-btn"
            variant="outlined"
            onClick={handleCloseModal}
            onTouchStart={handleCloseModal}
          >
            {t('ok')}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ReportTypesPanel;
