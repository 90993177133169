import { apiClient } from '@/ApiClient';
import { addURLParameter, deleteURLParameter } from '@/router/router';
import {
  EOrder,
  EReportName,
  EReportTypes,
  EReportUnit,
  ESchoolTypes,
  ETimeFrames,
} from '@/store/enums';
import { loadFilterLists } from '@/store/filterListsSlice';
import { AppDispatch, store } from '@/store/index';
import { loadCurrentMls } from '@/store/mlsSlice';
import { initConfig } from '@/store/reportFieldsSlice';
import { loadReport, resetReport, setLoading } from '@/store/reportSlice';
import {
  IKeySettings,
  IKeySettingsInitialState,
  IUserSearch,
} from '@/store/types';
import { createSlice } from '@reduxjs/toolkit';

const savedSearch = {
  savedSearchList: [],
  savedSearch: null,
};

const filtersFields = {
  agentIds: [],
  officeIds: [],
  officeIdsInclude: 'true',
  firmIds: [],
  firmInclude: 'true',
  brandIds: [],
  brandInclude: 'true',
  firmsMin: '',
  firmsMax: '',
  officesMin: '',
  officesMax: '',
  agentsMin: '',
  agentsMax: '',
  registeredAgentsMin: '',
  registeredAgentsMax: '',
  listingUnitsMin: '',
  listingUnitsMax: '',
  listingUnitsPerAgentMin: '',
  listingUnitsPerAgentMax: '',
  listingUnitsPerRegisteredAgentMin: '',
  listingUnitsPerRegisteredAgentMax: '',
  sellingUnitsMin: '',
  sellingUnitsMax: '',
  sellingUnitsPerAgentMin: '',
  sellingUnitsPerAgentMax: '',
  sellingUnitsPerRegisteredAgentMin: '',
  sellingUnitsPerRegisteredAgentMax: '',
  totalUnitsMin: '',
  totalUnitsMax: '',
  totalUnitsPerAgentMin: '',
  totalUnitsPerAgentMax: '',
  totalUnitsPerRegisteredAgentMin: '',
  totalUnitsPerRegisteredAgentMax: '',
  listingVolumeMin: '',
  listingVolumeMax: '',
  listingVolumePerAgentMin: '',
  listingVolumePerAgentMax: '',
  listingVolumePerRegisteredAgentMin: '',
  listingVolumePerRegisteredAgentMax: '',
  sellingVolumeMin: '',
  sellingVolumeMax: '',
  sellingVolumePerAgentMin: '',
  sellingVolumePerAgentMax: '',
  sellingVolumePerRegisteredAgentMin: '',
  sellingVolumePerRegisteredAgentMax: '',
  totalVolumeMin: '',
  totalVolumeMax: '',
  totalVolumePerAgentMin: '',
  totalVolumePerAgentMax: '',
  totalVolumePerRegisteredAgentMin: '',
  totalVolumePerRegisteredAgentMax: '',
  avgPriceMin: '',
  avgPriceMax: '',
  avgRatioMin: '',
  avgRatioMax: '',
  avgDOMMin: '',
  avgDOMMax: '',
  previousUnitsMin: '',
  previousUnitsMax: '',
  currentUnitsMin: '',
  currentUnitsMax: '',
  growthUnitsMin: '',
  growthUnitsMax: '',
  previousVolumeMin: '',
  previousVolumeMax: '',
  currentVolumeMin: '',
  currentVolumeMax: '',
  growthVolumeMin: '',
  growthVolumeMax: '',
  previousAvgPriceMin: '',
  previousAvgPriceMax: '',
  currentAvgPriceMin: '',
  currentAvgPriceMax: '',
  growthAvgPriceMin: '',
  growthAvgPriceMax: '',
  previousAvgRatioMin: '',
  previousAvgRatioMax: '',
  currentAvgRatioMin: '',
  currentAvgRatioMax: '',
  growthAvgRatioMin: '',
  growthAvgRatioMax: '',
  previousAvgDOMMin: '',
  previousAvgDOMMax: '',
  currentAvgDOMMin: '',
  currentAvgDOMMax: '',
  growthAvgDOMMin: '',
  growthAvgDOMMax: '',
  unitPriceMin: '',
  unitPriceMax: '',
  unitRatioMin: '',
  unitRatioMax: '',
  unitDOMMin: '',
  unitDOMMax: '',
  closedUnitsMin: '',
  closedUnitsMax: '',
  closedVolumeMin: '',
  closedVolumeMax: '',
  pendingUnitsMin: '',
  pendingUnitsMax: '',
  pendingVolumeMin: '',
  pendingVolumeMax: '',
  expiredUnitsMin: '',
  expiredUnitsMax: '',
  expiredVolumeMin: '',
  expiredVolumeMax: '',
  withdrawnUnitsMin: '',
  withdrawnUnitsMax: '',
  withdrawnVolumeMin: '',
  withdrawnVolumeMax: '',
  percentageUnitsMin: '',
  percentageUnitsMax: '',
  percentageVolumeMin: '',
  percentageVolumeMax: '',
};

const MLSDependentFields = {
  county: [],
  city: [],
  zipCode: [],
  district: [],
  type: [],
  propertyType: [],
  style: [],
  subdivision: [],
  school: [],
  officeCity: [],
  officeZip: [],
  officeCityInclude: 'true',
  officeZipInclude: 'true',
  officeCountyInclude: 'true',
  officeCounty: [],
  area: '',
  officeArea: '',
};

const initialFields = {
  mls: null,
  timeFrame: ETimeFrames.YEAR,
  reportType: EReportTypes.COMBINED,
  reportName: EReportName.AGENTS,
  reportUnit: EReportUnit.AGENTS,
  startDate: null,
  endDate: null,
  firstDate: null,
  lastDate: null,
  priceOver: null,
  priceUnder: null,
  orderDirection: null,
  orderBy: null,
  secondaryOrderBy: null,
  data: null,
  chartType: null,
  timePeriod: null,
  yearBuiltMin: null,
  yearBuiltMax: null,
  bedroomsMin: null,
  bedroomsMax: null,
  finishedSquareFootageMin: null,
  finishedSquareFootageMax: null,
  totalSquareFootageMin: null,
  totalSquareFootageMax: null,
  bathroomsFullMin: null,
  bathroomsFullMax: null,
  bathroomsHalfMin: null,
  bathroomsHalfMax: null,
  schoolType: ESchoolTypes.SCHOOL_JUNIOR,
  page: 1,
  perPage: 10,
  ...MLSDependentFields,
  ...filtersFields,
  ...savedSearch,
};

const includedReports = [
  EReportName.AGENT_HISTORY,
  EReportName.PERFORMANCE,
  EReportName.AGENT_TRANSACTIONS,
  EReportName.OFFICE_TRANSACTIONS,
  EReportName.FIRM_TRANSACTIONS,
  EReportName.BRAND_TRANSACTIONS,
];

const includedReportsWithoutData = [
  EReportName.AGENTS_DISTRIBUTION,
  EReportName.OFFICES_DISTRIBUTION,
  EReportName.FIRMS_DISTRIBUTION,
  EReportName.BRANDS_DISTRIBUTION,
  EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
  EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
];

export const keySettingsSlice = createSlice({
  name: 'keySettings',
  initialState: {
    ...initialFields,
  },
  reducers: {
    initState: (state, action) =>
      Object.assign(state || {}, action.payload.data),
    setKeySettings: (
      state,
      action: {
        payload: {
          key: string;
          value:
            | any
            | Array<number | string>
            | number
            | string
            | null
            | boolean
            | undefined;
        };
        type: string;
      }
    ) => {
      const { key, value } = action.payload;

      updateQuery(state, key, value);

      Object.assign(state || {}, {
        [key]: value,
      });
    },

    resetKeySettings: (state, action) => {
      const {
        mls,
        timeFrame,
        startDate,
        endDate,
        reportUnit,
        reportName,
        orderDirection,
        chartType,
        perPage,
      } = action.payload;
      updateQuery(state, 'timeFrame', timeFrame);
      updateQuery(state, 'startDate', startDate);
      updateQuery(state, 'endDate', endDate);
      updateQuery(state, 'orderDirection', orderDirection);
      updateQuery(state, 'area', '');
      updateQuery(state, 'officeArea', '');

      const { agentIds, officeIds, brandIds, firmIds, ...spreadFields } =
        filtersFields;

      if (includedReports.includes(reportName)) {
        const { agentIds, officeIds, brandIds, firmIds, ...fields } =
          initialFields;
        for (const [key, value] of Object.entries(spreadFields)) {
          updateQuery(state, key, value);
        }
        Object.assign(state, {
          ...fields,
          mls,
          timeFrame,
          startDate,
          endDate,
          reportUnit,
          reportName,
          orderDirection,
          chartType,
          perPage,
        });
      } else if (includedReportsWithoutData.includes(reportName)) {
        const { agentIds, officeIds, brandIds, firmIds, data, ...fields } =
          initialFields;

        for (const [key, value] of Object.entries(spreadFields)) {
          updateQuery(state, key, value);
        }

        Object.assign(state, {
          ...fields,
          mls,
          timeFrame,
          startDate,
          endDate,
          reportUnit,
          reportName,
          orderDirection,
          chartType,
          perPage,
        });
      } else {
        const { ...fields } = initialFields;
        for (const [key, value] of Object.entries(filtersFields)) {
          updateQuery(state, key, value);
        }
        Object.assign(state, {
          ...fields,
          mls,
          timeFrame,
          startDate,
          endDate,
          reportUnit,
          reportName,
          orderDirection,
          chartType,
          perPage,
        });
      }
    },

    resetFilters: (state, action) => {
      const { agentIds, officeIds, brandIds, firmIds, ...spreadFields } =
        filtersFields;

      if (includedReports.includes(action.payload)) {
        for (const [key, value] of Object.entries(spreadFields)) {
          updateQuery(state, key, value);
        }
        Object.assign(state, {
          ...spreadFields,
        });
      } else if (includedReportsWithoutData.includes(action.payload)) {
        for (const [key, value] of Object.entries(spreadFields)) {
          updateQuery(state, key, value);
        }
        Object.assign(state, {
          ...spreadFields,
        });
      } else {
        for (const [key, value] of Object.entries(filtersFields)) {
          updateQuery(state, key, value);
        }
        Object.assign(state, {
          ...filtersFields,
        });
      }
    },

    resetToDefaultReport: (state) => {
      const { reportName, reportUnit } = initialFields;

      Object.assign(state, {
        reportName,
        reportUnit,
        orderDirection: EOrder.ASC,
      });
    },

    resetMLSDependentSettings: (state) =>
      Object.assign(state, {
        ...MLSDependentFields,
        page: 1,
        schoolType: ESchoolTypes.SCHOOL_JUNIOR,
      }),

    setSavedSearchList: (state, action) =>
      Object.assign(state, {
        savedSearchList: action.payload,
      }),

    setSavedSearch: (
      state,
      action: {
        payload: {
          value: IUserSearch | null;
        };
      }
    ) => {
      Object.assign(state, {
        savedSearch: action.payload.value,
      });
    },
  },
});

export const {
  initState,
  setKeySettings,
  resetKeySettings,
  resetMLSDependentSettings,
  resetFilters,
  setSavedSearchList,
  setSavedSearch,
  resetToDefaultReport,
} = keySettingsSlice.actions;

export const getKeySettings = (state: IKeySettingsInitialState) =>
  state.keySettings;

export const getSavedSearchListState = (state: IKeySettingsInitialState) =>
  state.keySettings.savedSearchList;

export const getSavedSearchState = (state: IKeySettingsInitialState) =>
  state.keySettings.savedSearch;

export default keySettingsSlice.reducer;

export const initKeySettings = () => async (dispatch: AppDispatch) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlMls = urlParams.get('mls');
  const mls = urlMls
    ? parseInt(urlMls)
    : store.getState().auth.settings.defaultMlsDatabase;
  const county = urlParams.get('county');
  const city = urlParams.get('city');
  const zipCode = urlParams.get('zipCode');
  const district = urlParams.get('district');
  const subdivision = urlParams.get('subdivision');
  const area = urlParams.get('area');
  const officeArea = urlParams.get('officeArea');
  const officeCounty = urlParams.get('officeCounty');
  const officeZip = urlParams.get('officeZip');
  const officeCity = urlParams.get('officeCity');
  const officeCityInclude = urlParams.get('officeCityInclude');
  const officeZipInclude = urlParams.get('officeZipInclude');
  const officeCountyInclude = urlParams.get('officeCountyInclude');
  const yearBuiltMin = urlParams.get('yearBuiltMin');
  const yearBuiltMax = urlParams.get('yearBuiltMax');
  const bedroomsMin = urlParams.get('bedroomsMin');
  const bedroomsMax = urlParams.get('bedroomsMax');
  const finishedSquareFootageMin = urlParams.get('finishedSquareFootageMin');
  const finishedSquareFootageMax = urlParams.get('finishedSquareFootageMax');
  const totalSquareFootageMin = urlParams.get('totalSquareFootageMin');
  const totalSquareFootageMax = urlParams.get('totalSquareFootageMax');
  const bathroomsFullMin = urlParams.get('bathroomsFullMin');
  const bathroomsFullMax = urlParams.get('bathroomsFullMax');
  const bathroomsHalfMin = urlParams.get('bathroomsHalfMin');
  const bathroomsHalfMax = urlParams.get('bathroomsHalfMax');
  const growthUnitsMin = urlParams.get('growthUnitsMin');
  const growthUnitsMax = urlParams.get('growthUnitsMax');
  const school = urlParams.get('school');
  const priceOver = urlParams.get('priceOver');
  const priceUnder = urlParams.get('priceUnder');
  const propertyType = urlParams.get('propertyType');
  const type = urlParams.get('type');
  const style = urlParams.get('style');
  const reportType = urlParams.get('reportType');
  const timeFrame = urlParams.get('timeFrame');
  const page = urlParams.get('page');
  const perPage = urlParams.get('perPage');
  const startDate = urlParams.get('startDate');
  const endDate = urlParams.get('endDate');
  const firstDate = urlParams.get('firstDate');
  const lastDate = urlParams.get('lastDate');
  const officeIds = urlParams.get('officeIds');
  const officeIdsInclude = urlParams.get('officeIdsInclude');
  const brandIds = urlParams.get('brandIds');
  const firmIds = urlParams.get('firmIds');
  const agentIds = urlParams.get('agentIds');
  const listingAgentIds = urlParams.get('listingAgentIds');
  const sellingAgentIds = urlParams.get('sellingAgentIds');
  const firmsMin = urlParams.get('firmsMin');
  const firmsMax = urlParams.get('firmsMax');
  const officesMin = urlParams.get('officesMin');
  const officesMax = urlParams.get('officesMax');
  const agentsMin = urlParams.get('agentsMin');
  const agentsMax = urlParams.get('agentsMax');
  const registeredAgentsMin = urlParams.get('registeredAgentsMin');
  const registeredAgentsMax = urlParams.get('registeredAgentsMax');
  const listingUnitsMin = urlParams.get('listingUnitsMin');
  const listingUnitsMax = urlParams.get('listingUnitsMax');
  const listingUnitsPerAgentMin = urlParams.get('listingUnitsPerAgentMin');
  const listingUnitsPerAgentMax = urlParams.get('listingUnitsPerAgentMax');
  const listingUnitsPerRegisteredAgentMin = urlParams.get(
    'listingUnitsPerRegisteredAgentMin'
  );
  const listingUnitsPerRegisteredAgentMax = urlParams.get(
    'listingUnitsPerRegisteredAgentMax'
  );
  const sellingUnitsMin = urlParams.get('sellingUnitsMin');
  const sellingUnitsMax = urlParams.get('sellingUnitsMax');
  const sellingUnitsPerAgentMin = urlParams.get('sellingUnitsPerAgentMin');
  const sellingUnitsPerAgentMax = urlParams.get('sellingUnitsPerAgentMax');
  const sellingUnitsPerRegisteredAgentMin = urlParams.get(
    'sellingUnitsPerRegisteredAgentMin'
  );
  const sellingUnitsPerRegisteredAgentMax = urlParams.get(
    'sellingUnitsPerRegisteredAgentMax'
  );
  const totalUnitsMin = urlParams.get('totalUnitsMin');
  const totalUnitsMax = urlParams.get('totalUnitsMax');
  const totalUnitsPerAgentMin = urlParams.get('totalUnitsPerAgentMin');
  const totalUnitsPerAgentMax = urlParams.get('totalUnitsPerAgentMax');
  const totalUnitsPerRegisteredAgentMin = urlParams.get(
    'totalUnitsPerRegisteredAgentMin'
  );
  const totalUnitsPerRegisteredAgentMax = urlParams.get(
    'totalUnitsPerRegisteredAgentMax'
  );
  const listingVolumeMin = urlParams.get('listingVolumeMin');
  const listingVolumeMax = urlParams.get('listingVolumeMax');
  const listingVolumePerAgentMin = urlParams.get('listingVolumePerAgentMin');
  const listingVolumePerAgentMax = urlParams.get('listingVolumePerAgentMax');
  const listingVolumePerRegisteredAgentMin = urlParams.get(
    'listingVolumePerRegisteredAgentMin'
  );
  const listingVolumePerRegisteredAgentMax = urlParams.get(
    'listingVolumePerRegisteredAgentMax'
  );
  const sellingVolumeMin = urlParams.get('sellingVolumeMin');
  const sellingVolumeMax = urlParams.get('sellingVolumeMax');
  const sellingVolumePerAgentMin = urlParams.get('sellingVolumePerAgentMin');
  const sellingVolumePerAgentMax = urlParams.get('sellingVolumePerAgentMax');
  const sellingVolumePerRegisteredAgentMin = urlParams.get(
    'sellingVolumePerRegisteredAgentMin'
  );
  const sellingVolumePerRegisteredAgentMax = urlParams.get(
    'sellingVolumePerRegisteredAgentMax'
  );
  const totalVolumeMin = urlParams.get('totalVolumeMin');
  const totalVolumeMax = urlParams.get('totalVolumeMax');
  const totalVolumePerAgentMin = urlParams.get('totalVolumePerAgentMin');
  const totalVolumePerAgentMax = urlParams.get('totalVolumePerAgentMax');
  const totalVolumePerRegisteredAgentMin = urlParams.get(
    'totalVolumePerRegisteredAgentMin'
  );
  const totalVolumePerRegisteredAgentMax = urlParams.get(
    'totalVolumePerRegisteredAgentMax'
  );
  const avgPriceMin = urlParams.get('avgPriceMin');
  const avgPriceMax = urlParams.get('avgPriceMax');
  const avgRatioMin = urlParams.get('avgRatioMin');
  const avgRatioMax = urlParams.get('avgRatioMax');
  const avgDOMMin = urlParams.get('avgDOMMin');
  const avgDOMMax = urlParams.get('avgDOMMax');
  const reportName = urlParams.get('reportName');
  const reportUnit = urlParams.get('reportUnit');
  const orderBy = urlParams.get('orderBy');
  const orderDirection = urlParams.get('orderDirection');
  const data = urlParams.get('data');
  const previousUnitsMin = urlParams.get('previousUnitsMin');
  const previousUnitsMax = urlParams.get('previousUnitsMax');
  const currentUnitsMin = urlParams.get('currentUnitsMin');
  const currentUnitsMax = urlParams.get('currentUnitsMax');
  const previousVolumeMin = urlParams.get('previousVolumeMin');
  const previousVolumeMax = urlParams.get('previousVolumeMax');
  const currentVolumeMin = urlParams.get('currentVolumeMin');
  const currentVolumeMax = urlParams.get('currentVolumeMax');
  const growthVolumeMin = urlParams.get('growthVolumeMin');
  const growthVolumeMax = urlParams.get('growthVolumeMax');
  const previousAvgPriceMin = urlParams.get('previousAvgPriceMin');
  const previousAvgPriceMax = urlParams.get('previousAvgPriceMax');
  const currentAvgPriceMin = urlParams.get('currentAvgPriceMin');
  const currentAvgPriceMax = urlParams.get('currentAvgPriceMax');
  const growthAvgPriceMin = urlParams.get('growthAvgPriceMin');
  const growthAvgPriceMax = urlParams.get('growthAvgPriceMax');
  const previousAvgRatioMin = urlParams.get('previousAvgRatioMin');
  const previousAvgRatioMax = urlParams.get('previousAvgRatioMax');
  const currentAvgRatioMin = urlParams.get('currentAvgRatioMin');
  const currentAvgRatioMax = urlParams.get('currentAvgRatioMax');
  const growthAvgRatioMin = urlParams.get('growthAvgRatioMin');
  const growthAvgRatioMax = urlParams.get('growthAvgRatioMax');
  const previousAvgDOMMin = urlParams.get('previousAvgDOMMin');
  const previousAvgDOMMax = urlParams.get('previousAvgDOMMax');
  const currentAvgDOMMin = urlParams.get('currentAvgDOMMin');
  const currentAvgDOMMax = urlParams.get('currentAvgDOMMax');
  const growthAvgDOMMin = urlParams.get('growthAvgDOMMin');
  const growthAvgDOMMax = urlParams.get('growthAvgDOMMax');
  const unitPriceMin = urlParams.get('unitPriceMin');
  const unitPriceMax = urlParams.get('unitPriceMax');
  const unitRatioMin = urlParams.get('unitRatioMin');
  const unitRatioMax = urlParams.get('unitRatioMax');
  const unitDOMMin = urlParams.get('unitDOMMin');
  const unitDOMMax = urlParams.get('unitDOMMax');
  const closedUnitsMin = urlParams.get('closedUnitsMin');
  const closedUnitsMax = urlParams.get('closedUnitsMax');
  const closedVolumeMin = urlParams.get('closedVolumeMin');
  const closedVolumeMax = urlParams.get('closedVolumeMax');
  const pendingUnitsMin = urlParams.get('pendingUnitsMin');
  const pendingUnitsMax = urlParams.get('pendingUnitsMax');
  const pendingVolumeMin = urlParams.get('pendingVolumeMin');
  const pendingVolumeMax = urlParams.get('pendingVolumeMax');
  const expiredUnitsMin = urlParams.get('expiredUnitsMin');
  const expiredUnitsMax = urlParams.get('expiredUnitsMax');
  const expiredVolumeMin = urlParams.get('expiredVolumeMin');
  const expiredVolumeMax = urlParams.get('expiredVolumeMax');
  const withdrawnUnitsMin = urlParams.get('withdrawnUnitsMin');
  const withdrawnUnitsMax = urlParams.get('withdrawnUnitsMax');
  const withdrawnVolumeMin = urlParams.get('withdrawnVolumeMin');
  const withdrawnVolumeMax = urlParams.get('withdrawnVolumeMax');
  const percentageUnitsMin = urlParams.get('percentageUnitsMin');
  const percentageUnitsMax = urlParams.get('percentageUnitsMax');
  const percentageVolumeMin = urlParams.get('percentageVolumeMin');
  const percentageVolumeMax = urlParams.get('percentageVolumeMax');

  const initSettings = {} as IKeySettings;

  if (mls) {
    initSettings.mls = mls;
    dispatch(loadCurrentMls(mls));
  }

  if (reportType) {
    if (
      [
        EReportName.AGENTS_STATUS,
        EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
        EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
        EReportName.OFFICE_REGISTERED_AGENT_COUNT,
        EReportName.FIRM_REGISTERED_AGENT_COUNT,
        EReportName.BRAND_REGISTERED_AGENT_COUNT,
        EReportName.OFFICE_MIGRATION,
        EReportName.MARKET,
        EReportName.MARKET_COMPARATIVE,
      ].includes(reportName as EReportName)
    ) {
      initSettings.reportType = EReportTypes.COMBINED;
      updateQuery(store.getState(), 'reportType', EReportTypes.COMBINED);
    } else {
      initSettings.reportType = reportType as EReportTypes;
    }
  }

  if (officeCityInclude) {
    initSettings.officeCityInclude = officeCityInclude;
  }

  if (reportName) {
    initSettings.reportName = reportName;
  }

  if (orderDirection) {
    initSettings.orderDirection = orderDirection as EOrder;
  }

  if (reportUnit) {
    initSettings.reportUnit = reportUnit;
  }

  if (officeIds) {
    const arr = officeIds.split(',');
    initSettings.officeIds = arr.map((item) => parseInt(item));
  }

  if (brandIds) {
    const arr = brandIds.split(',');
    initSettings.brandIds = arr.map((item) => parseInt(item));
  }

  if (firmIds) {
    const arr = firmIds.split(',');
    initSettings.firmIds = arr.map((item) => parseInt(item));
  }

  if (agentIds) {
    const arr = agentIds.split(',');
    initSettings.agentIds = arr.map((item) => parseInt(item));
  }

  if (agentIds) {
    const arr = agentIds.split(',');
    initSettings.agentIds = arr.map((item) => parseInt(item));
  }

  if (listingAgentIds) {
    const arr = listingAgentIds.split(',');
    initSettings.listingAgentIds = arr.map((item) => parseInt(item));
  }

  if (sellingAgentIds) {
    const arr = sellingAgentIds.split(',');
    initSettings.sellingAgentIds = arr.map((item) => parseInt(item));
  }

  if (officeIdsInclude) {
    initSettings.officeIdsInclude = officeIdsInclude;
  }

  if (officeZipInclude) {
    initSettings.officeZipInclude = officeZipInclude;
  }

  if (firmsMin) {
    initSettings.firmsMin = firmsMin;
  }

  if (firmsMax) {
    initSettings.firmsMax = firmsMax;
  }

  if (officesMin) {
    initSettings.officesMin = officesMin;
  }

  if (officesMax) {
    initSettings.officesMax = officesMax;
  }

  if (agentsMin) {
    initSettings.agentsMin = agentsMin;
  }

  if (agentsMax) {
    initSettings.agentsMax = agentsMax;
  }

  if (registeredAgentsMin) {
    initSettings.registeredAgentsMin = registeredAgentsMin;
  }

  if (registeredAgentsMax) {
    initSettings.registeredAgentsMax = registeredAgentsMax;
  }

  if (listingUnitsMin) {
    initSettings.listingUnitsMin = listingUnitsMin;
  }

  if (listingUnitsMax) {
    initSettings.listingUnitsMax = listingUnitsMax;
  }

  if (listingUnitsPerAgentMin) {
    initSettings.listingUnitsPerAgentMin = listingUnitsPerAgentMin;
  }

  if (listingUnitsPerAgentMax) {
    initSettings.listingUnitsPerAgentMax = listingUnitsPerAgentMax;
  }

  if (listingUnitsPerRegisteredAgentMin) {
    initSettings.listingUnitsPerRegisteredAgentMin =
      listingUnitsPerRegisteredAgentMin;
  }

  if (listingUnitsPerRegisteredAgentMax) {
    initSettings.listingUnitsPerRegisteredAgentMax =
      listingUnitsPerRegisteredAgentMax;
  }

  if (sellingUnitsMin) {
    initSettings.sellingUnitsMin = sellingUnitsMin;
  }

  if (sellingUnitsMax) {
    initSettings.sellingUnitsMax = sellingUnitsMax;
  }

  if (sellingUnitsPerAgentMin) {
    initSettings.sellingUnitsPerAgentMin = sellingUnitsPerAgentMin;
  }

  if (sellingUnitsPerAgentMax) {
    initSettings.sellingUnitsPerAgentMax = sellingUnitsPerAgentMax;
  }

  if (sellingUnitsPerRegisteredAgentMin) {
    initSettings.sellingUnitsPerRegisteredAgentMin =
      sellingUnitsPerRegisteredAgentMin;
  }

  if (sellingUnitsPerRegisteredAgentMax) {
    initSettings.sellingUnitsPerRegisteredAgentMax =
      sellingUnitsPerRegisteredAgentMax;
  }

  if (totalUnitsMin) {
    initSettings.totalUnitsMin = totalUnitsMin;
  }

  if (totalUnitsMax) {
    initSettings.totalUnitsMax = totalUnitsMax;
  }

  if (totalUnitsPerAgentMin) {
    initSettings.totalUnitsPerAgentMin = totalUnitsPerAgentMin;
  }

  if (totalUnitsPerAgentMax) {
    initSettings.totalUnitsPerAgentMax = totalUnitsPerAgentMax;
  }

  if (totalUnitsPerRegisteredAgentMin) {
    initSettings.totalUnitsPerRegisteredAgentMin =
      totalUnitsPerRegisteredAgentMin;
  }

  if (totalUnitsPerRegisteredAgentMax) {
    initSettings.totalUnitsPerRegisteredAgentMax =
      totalUnitsPerRegisteredAgentMax;
  }

  if (listingVolumeMin) {
    initSettings.listingVolumeMin = listingVolumeMin;
  }

  if (listingVolumeMax) {
    initSettings.listingVolumeMax = listingVolumeMax;
  }

  if (listingVolumePerAgentMin) {
    initSettings.listingVolumePerAgentMin = listingVolumePerAgentMin;
  }

  if (listingVolumePerAgentMax) {
    initSettings.listingVolumePerAgentMax = listingVolumePerAgentMax;
  }

  if (listingVolumePerRegisteredAgentMin) {
    initSettings.listingVolumePerRegisteredAgentMin =
      listingVolumePerRegisteredAgentMin;
  }

  if (listingVolumePerRegisteredAgentMax) {
    initSettings.listingVolumePerRegisteredAgentMax =
      listingVolumePerRegisteredAgentMax;
  }

  if (sellingVolumeMin) {
    initSettings.sellingVolumeMin = sellingVolumeMin;
  }

  if (sellingVolumeMax) {
    initSettings.sellingVolumeMax = sellingVolumeMax;
  }

  if (sellingVolumePerAgentMin) {
    initSettings.sellingVolumePerAgentMin = sellingVolumePerAgentMin;
  }

  if (sellingVolumePerAgentMax) {
    initSettings.sellingVolumePerAgentMax = sellingVolumePerAgentMax;
  }

  if (sellingVolumePerRegisteredAgentMin) {
    initSettings.sellingVolumePerRegisteredAgentMin =
      sellingVolumePerRegisteredAgentMin;
  }

  if (sellingVolumePerRegisteredAgentMax) {
    initSettings.sellingVolumePerRegisteredAgentMax =
      sellingVolumePerRegisteredAgentMax;
  }

  if (totalVolumeMin) {
    initSettings.totalVolumeMin = totalVolumeMin;
  }

  if (totalVolumeMax) {
    initSettings.totalVolumeMax = totalVolumeMax;
  }

  if (totalVolumePerAgentMin) {
    initSettings.totalVolumePerAgentMin = totalVolumePerAgentMin;
  }

  if (totalVolumePerAgentMax) {
    initSettings.totalVolumePerAgentMax = totalVolumePerAgentMax;
  }

  if (totalVolumePerRegisteredAgentMin) {
    initSettings.totalVolumePerRegisteredAgentMin =
      totalVolumePerRegisteredAgentMin;
  }

  if (totalVolumePerRegisteredAgentMax) {
    initSettings.totalVolumePerRegisteredAgentMax =
      totalVolumePerRegisteredAgentMax;
  }

  if (avgPriceMin) {
    initSettings.avgPriceMin = avgPriceMin;
  }

  if (avgPriceMax) {
    initSettings.avgPriceMax = avgPriceMax;
  }

  if (avgRatioMin) {
    initSettings.avgRatioMin = avgRatioMin;
  }

  if (avgRatioMax) {
    initSettings.avgRatioMax = avgRatioMax;
  }

  if (avgDOMMin) {
    initSettings.avgDOMMin = avgDOMMin;
  }

  if (avgDOMMax) {
    initSettings.avgDOMMax = avgDOMMax;
  }

  if (officeCountyInclude) {
    initSettings.officeCountyInclude = officeCountyInclude;
  }

  if (priceOver) {
    initSettings.priceOver = priceOver;
  }

  if (priceUnder) {
    initSettings.priceUnder = priceUnder;
  }

  if (propertyType) {
    const arr = propertyType.split(',');
    initSettings.propertyType = arr.map((item) => parseInt(item));
  }

  if (type) {
    const arr = type.split(',');
    initSettings.type = arr.map((item) => parseInt(item));
  }

  if (style) {
    const arr = style.split(',');
    initSettings.style = arr.map((item) => parseInt(item));
  }

  if (yearBuiltMin) {
    initSettings.yearBuiltMin = parseInt(yearBuiltMin);
  }

  if (area) {
    initSettings.area = area;
  }

  if (officeArea) {
    initSettings.officeArea = officeArea;
  }

  if (yearBuiltMax) {
    initSettings.yearBuiltMax = parseInt(yearBuiltMax);
  }

  if (bedroomsMin) {
    initSettings.bedroomsMin = parseInt(bedroomsMin);
  }

  if (bedroomsMax) {
    initSettings.bedroomsMax = parseInt(bedroomsMax);
  }

  if (finishedSquareFootageMin) {
    initSettings.finishedSquareFootageMin = parseInt(finishedSquareFootageMin);
  }

  if (finishedSquareFootageMax) {
    initSettings.finishedSquareFootageMax = parseInt(finishedSquareFootageMax);
  }

  if (totalSquareFootageMin) {
    initSettings.totalSquareFootageMin = parseInt(totalSquareFootageMin);
  }

  if (totalSquareFootageMax) {
    initSettings.totalSquareFootageMax = parseInt(totalSquareFootageMax);
  }

  if (bathroomsFullMin) {
    initSettings.bathroomsFullMin = parseInt(bathroomsFullMin);
  }

  if (bathroomsFullMax) {
    initSettings.bathroomsFullMax = parseInt(bathroomsFullMax);
  }

  if (bathroomsHalfMin) {
    initSettings.bathroomsHalfMin = parseInt(bathroomsHalfMin);
  }

  if (bathroomsHalfMax) {
    initSettings.bathroomsHalfMax = parseInt(bathroomsHalfMax);
  }

  if (growthUnitsMin) {
    initSettings.growthUnitsMin = growthUnitsMin;
  }

  if (growthUnitsMax) {
    initSettings.growthUnitsMax = growthUnitsMax;
  }

  if (county) {
    const arr = county.split(',');
    initSettings.county = arr.map((item) => parseInt(item));
  }

  if (city) {
    const arr = city.split(',');
    initSettings.city = arr.map((item) => parseInt(item));
  }

  if (zipCode) {
    const arr = zipCode.split(',');
    initSettings.zipCode = arr.map((item) => parseInt(item));
  }

  if (district) {
    const arr = district.split(',');
    initSettings.district = arr.map((item) => parseInt(item));
  }

  if (subdivision) {
    const arr = subdivision.split(',');
    initSettings.subdivision = arr.map((item) => parseInt(item));
  }

  if (officeCounty) {
    const arr = officeCounty.split(',');
    initSettings.officeCounty = arr.map((item) => parseInt(item));
  }

  if (officeCity) {
    const arr = officeCity.split(',');
    initSettings.officeCity = arr.map((item) => parseInt(item));
  }

  if (officeZip) {
    const arr = officeZip.split(',');
    initSettings.officeZip = arr.map((item) => parseInt(item));
  }

  if (school) {
    const arr = school.split(',');
    initSettings.school = arr.map((item) => parseInt(item));
  }

  if (timeFrame) {
    initSettings.timeFrame = timeFrame;

    if (timeFrame === 'custom' && startDate && endDate) {
      initSettings.startDate = startDate;
      initSettings.endDate = endDate;
    }
  }

  if (perPage) {
    initSettings.perPage = parseInt(perPage);
  }

  if (page) {
    initSettings.page = parseInt(page);
  }

  if (orderBy) {
    initSettings.orderBy = orderBy as any;
  }

  if (firstDate) {
    initSettings.firstDate = firstDate;
  }

  if (lastDate) {
    initSettings.lastDate = lastDate;
  }

  if (
    [
      EReportName.AGENTS_DISTRIBUTION,
      EReportName.OFFICES_DISTRIBUTION,
      EReportName.FIRMS_DISTRIBUTION,
      EReportName.BRANDS_DISTRIBUTION,
      EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
      EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
    ].includes(reportName as EReportName)
  ) {
    if (data) {
      initSettings.data = data;
    }
  }

  if (previousUnitsMin) {
    initSettings.previousUnitsMin = previousUnitsMin;
  }

  if (previousUnitsMax) {
    initSettings.previousUnitsMax = previousUnitsMax;
  }

  if (currentUnitsMin) {
    initSettings.currentUnitsMin = currentUnitsMin;
  }

  if (currentUnitsMax) {
    initSettings.currentUnitsMax = currentUnitsMax;
  }

  if (growthUnitsMin) {
    initSettings.growthUnitsMin = growthUnitsMin;
  }

  if (growthUnitsMax) {
    initSettings.growthUnitsMax = growthUnitsMax;
  }

  if (previousVolumeMin) {
    initSettings.previousVolumeMin = previousVolumeMin;
  }

  if (previousVolumeMax) {
    initSettings.previousVolumeMax = previousVolumeMax;
  }

  if (currentVolumeMin) {
    initSettings.currentVolumeMin = currentVolumeMin;
  }

  if (currentVolumeMax) {
    initSettings.currentVolumeMax = currentVolumeMax;
  }

  if (growthVolumeMin) {
    initSettings.growthVolumeMin = growthVolumeMin;
  }

  if (growthVolumeMax) {
    initSettings.growthVolumeMax = growthVolumeMax;
  }

  if (previousAvgPriceMin) {
    initSettings.previousAvgPriceMin = previousAvgPriceMin;
  }

  if (previousAvgPriceMax) {
    initSettings.previousAvgPriceMax = previousAvgPriceMax;
  }

  if (currentAvgPriceMin) {
    initSettings.currentAvgPriceMin = currentAvgPriceMin;
  }

  if (currentAvgPriceMax) {
    initSettings.currentAvgPriceMax = currentAvgPriceMax;
  }

  if (growthAvgPriceMin) {
    initSettings.growthAvgPriceMin = growthAvgPriceMin;
  }

  if (growthAvgPriceMax) {
    initSettings.growthAvgPriceMax = growthAvgPriceMax;
  }

  if (previousAvgRatioMin) {
    initSettings.previousAvgRatioMin = previousAvgRatioMin;
  }

  if (previousAvgRatioMax) {
    initSettings.previousAvgRatioMax = previousAvgRatioMax;
  }

  if (currentAvgRatioMin) {
    initSettings.currentAvgRatioMin = currentAvgRatioMin;
  }

  if (currentAvgRatioMax) {
    initSettings.currentAvgRatioMax = currentAvgRatioMax;
  }

  if (growthAvgRatioMin) {
    initSettings.growthAvgRatioMin = growthAvgRatioMin;
  }

  if (growthAvgRatioMax) {
    initSettings.growthAvgRatioMax = growthAvgRatioMax;
  }

  if (previousAvgDOMMin) {
    initSettings.previousAvgDOMMin = previousAvgDOMMin;
  }

  if (previousAvgDOMMax) {
    initSettings.previousAvgDOMMax = previousAvgDOMMax;
  }

  if (currentAvgDOMMin) {
    initSettings.currentAvgDOMMin = currentAvgDOMMin;
  }

  if (currentAvgDOMMax) {
    initSettings.currentAvgDOMMax = currentAvgDOMMax;
  }

  if (growthAvgDOMMin) {
    initSettings.growthAvgDOMMin = growthAvgDOMMin;
  }

  if (growthAvgDOMMax) {
    initSettings.growthAvgDOMMax = growthAvgDOMMax;
  }

  if (unitPriceMin) {
    initSettings.unitPriceMin = unitPriceMin;
  }

  if (unitPriceMax) {
    initSettings.unitPriceMax = unitPriceMax;
  }

  if (unitRatioMin) {
    initSettings.unitRatioMin = unitRatioMin;
  }

  if (unitRatioMax) {
    initSettings.unitRatioMax = unitRatioMax;
  }

  if (unitDOMMin) {
    initSettings.unitDOMMin = unitDOMMin;
  }

  if (unitDOMMax) {
    initSettings.unitDOMMax = unitDOMMax;
  }

  if (closedUnitsMin) {
    initSettings.closedUnitsMin = closedUnitsMin;
  }

  if (closedUnitsMax) {
    initSettings.closedUnitsMax = closedUnitsMax;
  }

  if (closedVolumeMin) {
    initSettings.closedVolumeMin = closedVolumeMin;
  }

  if (closedVolumeMax) {
    initSettings.closedVolumeMax = closedVolumeMax;
  }

  if (pendingUnitsMin) {
    initSettings.pendingUnitsMin = pendingUnitsMin;
  }

  if (pendingUnitsMax) {
    initSettings.pendingUnitsMax = pendingUnitsMax;
  }

  if (pendingVolumeMin) {
    initSettings.pendingVolumeMin = pendingVolumeMin;
  }

  if (pendingVolumeMax) {
    initSettings.pendingVolumeMax = pendingVolumeMax;
  }

  if (expiredUnitsMin) {
    initSettings.expiredUnitsMin = expiredUnitsMin;
  }

  if (expiredUnitsMax) {
    initSettings.expiredUnitsMax = expiredUnitsMax;
  }

  if (expiredVolumeMin) {
    initSettings.expiredVolumeMin = expiredVolumeMin;
  }

  if (expiredVolumeMax) {
    initSettings.expiredVolumeMax = expiredVolumeMax;
  }

  if (withdrawnUnitsMin) {
    initSettings.withdrawnUnitsMin = withdrawnUnitsMin;
  }

  if (withdrawnUnitsMax) {
    initSettings.withdrawnUnitsMax = withdrawnUnitsMax;
  }

  if (withdrawnVolumeMin) {
    initSettings.withdrawnVolumeMin = withdrawnVolumeMin;
  }

  if (withdrawnVolumeMax) {
    initSettings.withdrawnVolumeMax = withdrawnVolumeMax;
  }

  if (percentageUnitsMin) {
    initSettings.percentageUnitsMin = percentageUnitsMin;
  }

  if (percentageUnitsMax) {
    initSettings.percentageUnitsMax = percentageUnitsMax;
  }

  if (percentageVolumeMin) {
    initSettings.percentageVolumeMin = percentageVolumeMin;
  }

  if (percentageVolumeMax) {
    initSettings.percentageVolumeMax = percentageVolumeMax;
  }

  await dispatch(initState({ data: initSettings }));
};

const updateQuery = (state: any, key: string, value: any) => {
  if (!value) {
    deleteURLParameter(key);
  } else if (value instanceof Array) {
    value.length ? addURLParameter(key, value.join()) : deleteURLParameter(key);
  } else {
    switch (key) {
      case 'schoolType':
        return state.school?.length
          ? addURLParameter(key, value)
          : deleteURLParameter(key);
      default:
        return value ? addURLParameter(key, value) : deleteURLParameter(key);
    }
  }
};

export const getSavedSearchList = () => (dispatch: AppDispatch) => {
  return (
    apiClient
      .userSearch()
      //@ts-ignore
      .getUserSearchList(store.getState().keySettings.mls)
      .then(async (data) => {
        dispatch(setSavedSearchList(data));
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  );
};

export const getSavedSearchById = (id: number) => (dispatch: AppDispatch) => {
  return apiClient
    .userSearch()
    .getUserSearch(id)
    .then(async (data) => {
      await dispatch(setLoading());
      await dispatch(resetReport({ reset: false }));
      await dispatch(resetMLSDependentSettings());

      await dispatch(
        setKeySettings({
          key: 'mls',
          // @ts-ignore
          value: parseInt(data.mlsId),
        })
      );

      await dispatch(loadFilterLists(false));
      // @ts-ignore
      await dispatch(loadCurrentMls(parseInt(data.mlsId)));

      const keyFields = Object.keys(MLSDependentFields).map(
        (key: string) => key as keyof IKeySettings
      );

      const objectData = (() => {
        const {
          agentIds,
          officeIds,
          brandIds,
          firmIds,
          orderDirection,
          ...spreadFields
        } = data;
        if (
          store.getState().keySettings.reportName === EReportName.PERFORMANCE
        ) {
          return Object.entries(spreadFields);
        } else {
          return Object.entries(data);
        }
      })();

      const keyFiltersFields = Object.keys(filtersFields).map(
        (key: string) => key as keyof IKeySettings
      );

      objectData.map(([key, value]) => {
        if (key === 'endDate' || key === 'startDate') {
          return;
        } else if (
          key === 'officeCityInclude' ||
          key === 'officeZipInclude' ||
          key === 'officeCountyInclude'
        ) {
          dispatch(setKeySettings({ key, value: value ? 'true' : 'false' }));
          // @ts-ignore
        } else if (keyFields.includes(key)) {
          if ((key === 'area' || key === 'officeArea') && data[key] === null) {
            dispatch(setKeySettings({ key, value: '' }));
            // @ts-ignore
          } else if (data[key] === null) {
            dispatch(setKeySettings({ key, value: [] }));
          } else {
            dispatch(setKeySettings({ key, value }));
          }
          // @ts-ignore
        } else if (keyFiltersFields.includes(key)) {
          if (
            (key === 'agentIds' ||
              key === 'officeIds' ||
              key === 'firmIds' ||
              key === 'brandIds') &&
            data[key] === null
          ) {
            dispatch(setKeySettings({ key, value: [] }));
            // @ts-ignore
          } else if (data[key] === null) {
            dispatch(setKeySettings({ key, value: '' }));
          } else {
            dispatch(setKeySettings({ key, value }));
          }
        } else {
          if (key !== 'mlsId') {
            dispatch(setKeySettings({ key, value }));
          }
        }
      });

      dispatch(initConfig());
      dispatch(loadReport());
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteSavedSearch = (id: number) => (dispatch: AppDispatch) => {
  return apiClient
    .userSearch()
    .deleteUserSearch(id)
    .then(() => {
      dispatch(getSavedSearchList());
      //@ts-ignore
      store.getState().keySettings.savedSearch?.id === id &&
        dispatch(setSavedSearch({ value: null }));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
